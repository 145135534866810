import React, { createContext, useState, useEffect } from 'react';
import { onSnapshot, query, collection} from 'firebase/firestore';
import useCollection from "../hooks/useCollection";
import useAuth from '../hooks/useAuth';

export const ToolsContext = createContext();

const ToolsContextProvider = (props) => {
  const [tools, setTools] = useState([]);
  const [myTools, setMyTools] = useState([]);
  const { db, user } = useAuth();
  const [docs, setDocs] = useState(null)
  const pathTools = `tools`
  const pathMyTools = `users/${user.id}/tools`

  //

  useEffect(() => {
    //
    const q = query(collection(db, pathTools));
		 onSnapshot(q, (querySnapshot) => {
		  const documents = [];
		  querySnapshot.forEach((doc) => {
			documents.push({id: doc.id, ...doc.data()});
			setTools({...documents, documents});
			// console.log(`useCollection documents.length= ${documents.length}`)
            console.log(tools)
		  });
		});

  }, [pathTools, pathMyTools]);

  return (
    <ToolsContext.Provider
      value={{
        tools,
        setTools,
        myTools,
        setMyTools
      }}
    >
      {props.children}
    </ToolsContext.Provider>
  );
};

export default ToolsContextProvider;
