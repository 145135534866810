import React, { createContext, useState, useEffect } from 'react';
import { onSnapshot, query, collection} from 'firebase/firestore';
import useCollection from "../hooks/useCollection";
import useAuth from '../hooks/useAuth';

export const WorldClockContext = createContext();

const WorldClockContextProvider = (props) => {
  const [timezones, setTimezones] = useState(null);
  const { db, user } = useAuth();
  const [docs, setDocs] = useState(null)
  const pathTimezones = `users/${user.id}/worldClock`

  //

  useEffect(() => {
    //
    const q = query(collection(db, pathTimezones));
		 onSnapshot(q, (querySnapshot) => {
		  const documents = [];
		  querySnapshot.forEach((doc) => {
			documents.push({id: doc.id, ...doc.data()});
			setTimezones(documents);
			// console.log(`useCollection documents.length= ${documents.length}`)
		  });
		});

  }, [pathTimezones]);

  return (
    <WorldClockContext.Provider
      value={{
        timezones,
        setTimezones
      }}
    >
      {props.children}
    </WorldClockContext.Provider>
  );
};

export default WorldClockContextProvider;
