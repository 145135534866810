import React, { createContext, useState, useEffect } from 'react';
import { onSnapshot, query, collection} from 'firebase/firestore';
import useCollection from "../hooks/useCollection";
import useAuth from '../hooks/useAuth';

export const HabitsContext = createContext();

const HabitsContextProvider = (props) => {
  const [habits, setHabits] = useState([]);
  const { db, user } = useAuth();
  const [docs, setDocs] = useState(null)
  const path = `users/${user.id}/habits`

  //

  useEffect(() => {
    const q = query(collection(db, path));
		 onSnapshot(q, (querySnapshot) => {
		  const documents = [];
		  querySnapshot.forEach((doc) => {
			documents.push({id: doc.id, ...doc.data()});
			setHabits({...documents, documents});
			// console.log(`useCollection documents.length= ${documents.length}`)
		  });
		});

  }, [path]);

  return (
    <HabitsContext.Provider
      value={{
        habits,
        setHabits
      }}
    >
      {props.children}
    </HabitsContext.Provider>
  );
};

export default HabitsContextProvider;
