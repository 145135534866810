import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import RestoreIcon from '@mui/icons-material/Restore';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

export default function NavbarBottom() {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", position:"fixed", bottom: 0 }}>
      <BottomNavigation
        showLabel
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction label="Habits" icon={<RestoreIcon />} onClick={() => navigate("../dashboard/habits/home", { replace: true })}/>
        <BottomNavigationAction label="Dashboard" icon={<HomeIcon />} onClick={() => navigate("../dashboard/home", { replace: true })}/>
        <BottomNavigationAction label="Tasks" icon={<LibraryAddCheckIcon />} />
      </BottomNavigation>
    </Box>
  );
}